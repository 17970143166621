<template>
  <div class="container">
    <el-tabs v-model="status" @tab-click="statusChange">
      <el-tab-pane label="拼团中" name="2" />
      <el-tab-pane label="拼团成功" name="3" />
      <el-tab-pane label="拼团失败" name="4" />
      <el-tab-pane label="全部" name="0" />
    </el-tabs>
    <div class="searchbox">
      <el-form inline>
        <!-- <div class="searchBox"> -->
        <el-form-item label="团购编号">
          <el-input
            v-model="formInfo.order_group_sn"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="团长信息">
          <el-input
            v-model="formInfo.nick_name"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item>
        <el-form-item label="开团时间">
          <el-date-picker
            v-model="formInfo.start_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="成团时间">
          <el-date-picker
            v-model="formInfo.completion_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="截止时间">
          <el-date-picker
            v-model="formInfo.end_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form>
    </div>

    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; min-width: 1000px"
        :header-cell-style="{ background: '#F5F5F5' }"
      >
        <el-table-column label="团购编号" width="240px" prop="order_group_sn">
        </el-table-column>
        <el-table-column label="团购商品" width="240px">
          <template slot-scope="scope">
            <div style="display:flex ;align-items: center;">
              <img
                :src="scope.row.picture_str"
                style="width: 60px;height: 45px;"
              />
              <div style="flex: 1;">{{ scope.row.goods_name }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="团长信息" width="180px">
          <tempalte slot-scope="scope">
            <div>{{ scope.row.nick_name }}/{{ scope.row.phone }}</div>
          </tempalte>
        </el-table-column>
        <el-table-column label="开团时间" min-width="100px" prop="start_time">
        </el-table-column>
        <el-table-column label="团购状态" min-width="100px">
          <template slot-scope="scope">
            <!-- 成团时间/截止时间 -->
            <div v-if="scope.row.status == 3">
              拼团成功
            </div>
            <!-- 失败时间/截止时间 -->
            <div v-if="scope.row.status == 4">
              拼团失败
            </div>
            <!--截止时间 -->
            <div v-if="!(scope.row.status == 3 || scope.row.status == 4)">
              拼团中
            </div>
          </template>
        </el-table-column>
        <el-table-column label="拼团进度" min-width="100px">
          <template slot-scope="scope">
            <div>{{ scope.row.count }}/{{ scope.row.group_num }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :label="
            status == 3
              ? '成团时间'
              : status == 4
              ? '失败时间'
              : status == 2
              ? '截止时间'
              : '成团（失败）时间/截止时间'
          "
          min-width="170px"
        >
          <template slot-scope="scope">
            <!-- 成团时间/截止时间 -->
            <div v-if="scope.row.status == 3">
              {{ scope.row.completion_time }}/{{ scope.row.end_time }}
            </div>
            <!-- 失败时间/截止时间 -->
            <div v-if="scope.row.status == 4">
              {{ scope.row.completion_time }}/{{ scope.row.end_time }}
            </div>
            <!--截止时间 -->
            <div v-if="!(scope.row.status == 3 || scope.row.status == 4)">
              {{ scope.row.end_time }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="auto">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="toOrderDetail(scope.row.order_group_id)"
              v-if="
                module_id_array.includes('190') ||
                  module_id_array.includes('999')
              "
              >查看拼团详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      style="margin: 30px 0 40px 0"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "groupData",
  data() {
    return {
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      status: "2",
      total: 0,
      page: 1,
      pageNumber: 10,
      formInfo: {
        order_group_sn: "",
        nick_name: "",
        start_time: [],
        end_time: [],
        completion_time: [],
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      console.log(this.formInfo.start_time);
      const data = {
        page: this.page,
        pageNumber: this.pageNumber,
        order_group_sn: this.formInfo.order_group_sn,
        nick_name: this.formInfo.nick_name,
        start_time_start: this.formInfo.start_time[0]
          ? this.dayjs(this.formInfo.start_time[0]).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        start_time_end: this.formInfo.start_time[1]
          ? this.dayjs(this.formInfo.start_time[1]).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        end_time_start: this.formInfo.end_time[0]
          ? this.dayjs(this.formInfo.end_time[0]).format("YYYY-MM-DD HH:mm:ss")
          : "",
        end_time_end: this.formInfo.end_time[1]
          ? this.dayjs(this.formInfo.end_time[1]).format("YYYY-MM-DD HH:mm:ss")
          : "",
        completion_time_start: this.formInfo.completion_time[0]
          ? this.dayjs(this.formInfo.completion_time[0]).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        completion_time_end: this.formInfo.completion_time[1]
          ? this.dayjs(this.formInfo.completion_time[1]).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "",
        status: this.status,
      };
      console.log("data", data);
      this.axios
        .post("/store/Shopactivity/getGroupOrderList", data)
        .then((res) => {
          console.log(res);
          this.total = res.data.total;
          this.tableData = res.data.list;
          this.tableData.map((el) => {
            el.start_time = this.dayjs(el.start_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            el.end_time = this.dayjs(el.end_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            el.completion_time = this.dayjs(el.completion_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            return el;
          });
        });
    },
    search() {
      this.page = 1;
      this.getList();
    },
    reset() {
      this.formInfo = {
        order_group_sn: "",
        nick_name: "",
        start_time: [],
        end_time: [],
        completion_time: [],
      };
      this.page = 1;
      this.pageNumber = 10;
      this.getList();
    },
    statusChange() {
      this.page = 1;
      this.getList();
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    toOrderDetail(val) {
      this.$router.push({
        path: "/order/GroupDetails",
        query: {
          order_group_id: val,
        },
      });
    },
  },
};
</script>
